<template>
  <div class="content">
    <div class="top">
      <a-input
        style="width: 150px; margin: 5px"
        size="small"
        placeholder="标题"
        v-model="params.title"
        @pressEnter="search"
        @change="valueNull"
      />
      <a-select
        allowClear
        v-model="params.recommendStatus"
        size="small"
        style="width: 150px; margin: 5px"
        placeholder="推荐"
      >
        <a-select-option :value="1">是</a-select-option>
        <a-select-option :value="0">否</a-select-option>
      </a-select>
      <a-select
        allowClear
        v-model="params.status"
        size="small"
        style="width: 150px; margin: 5px"
        placeholder="发布状态"
      >
        <a-select-option :value="1">已发布</a-select-option>
        <a-select-option :value="0">未发布</a-select-option>
      </a-select>
      <a-select
        allowClear
        @change="changeSelect"
        v-model="params.categoryIds"
        showSearch
        size="small"
        style="width: 150px; margin: 5px"
        placeholder="分类"
      >
        <a-select-option
          v-for="item of category_list"
          :key="item.id"
          :value="item.id"
          >{{ item.categoryName }}</a-select-option
        >
      </a-select>

      <button class="search" @click="search">搜索</button>
      <button class="add" size="large" style="width: 90px" @click="openModal()">
        添加文章
      </button>
    </div>
    <div class="table-content">
      <a-table
        :columns="list"
        :data-source="data"
        rowKey="id"
        @change="changePage"
        :pagination="pagination"
      >
        <div
          slot="showImage"
          slot-scope="imageUrl"
          style="
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <img
            @click="previewImg(imageUrl)"
            style="max-width: 50px; max-height: 50px"
            :src="imageUrl"
          />
        </div>

        <a slot="name" slot-scope="text">{{ text }}</a>
        <div slot="setup" slot-scope="row">
          <a @click="setState(2, row)" style="margin-right: 5px">{{
            row.recommendStatus == 0 ? "推荐" : "取消推荐"
          }}</a>
          <a
            v-if="row.status == 0"
            @click="setState(1, row)"
            style="margin-right: 5px"
            >发布</a
          >

          <a @click="openModal(row)" style="margin-right: 5px">编辑</a>
          <a-popconfirm
            title="确定要删除此项么？"
            ok-text="确认"
            cancel-text="取消"
            @confirm="remove(row.id)"
          >
            <a style="margin-left: 5px">删除</a>
          </a-popconfirm>
        </div>
      </a-table>
    </div>

    <a-modal
      centered
      :width="700"
      v-model="visible"
      @ok="checkForms"
      @cancel="cancel"
    >
      <template slot="footer" style="display: flex">
        <div>
          <a-button v-if="forms.status != 1" @click="editSave">保存</a-button>
          <a-button style="margin-right: 10px" @click="setPreview">{{
            showEditPriview == false ? "预览" : "编辑文章"
          }}</a-button>
          <a-upload
            style="margin-right: 10px"
            :key="imgKey"
            :action="ip + '/file/upload'"
            :multiple="true"
            :showUploadList="false"
            @change="handleChangeEdit"
          >
            <a-button> <a-icon type="upload" />添加图片</a-button>
          </a-upload>
          <a-button key="back" @click="cancel"> 取消 </a-button>
          <a-button key="submit" type="primary" @click="checkForms">
            确认
          </a-button>
        </div>
      </template>
      <div style="height: 700px; overflow-y: auto">
        <a-form-model
          v-if="visible"
          v-bind="layout"
          :model="forms"
          :rules="rules"
          ref="ruleForm"
        >
          <a-form-model-item label="标题" prop="title">
            <a-input placeholder="标题" v-model="forms.title"></a-input>
          </a-form-model-item>

          <a-form-model-item label="类别">
            <a-checkbox-group @change="changeTag" v-model="forms.categoryIds">
              <a-checkbox
                v-for="items of category_list"
                :key="items.id"
                :value="items.id"
                >{{ items.categoryName }}</a-checkbox
              >
            </a-checkbox-group>
            <!-- <a-checkable-tag
                        v-for="(item,index) of category_list"
                        :key="index"
                        :checked="item.checked"
                        @change="changeTag(index)"
                    >
                        {{item.categoryName}}
                    </a-checkable-tag> -->
          </a-form-model-item>
          <a-form-model-item label="大币种">
            <a-select
              @search="searchData"
              v-model="forms.coinKindId"
              :filter-option="untils.filterOption"
              showSearch
              placeholder="请选择"
            >
              <a-select-option
                v-for="(item, index) of currency_big_list"
                :key="index"
                :value="item.id"
                >{{ item.coinKindName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="用户" prop="userId">
            <div style="display:flex;">
              <a-select
                v-model="forms.userId"
                :filter-option="untils.filterOption"
                @search="searchUser"
                showSearch
                placeholder="请选择"
                allowClear
                style="margin-right: 8px"
              >
                <a-select-option
                  v-for="item of users"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.userName }}
                </a-select-option>
              </a-select>
              <a-input
                placeholder="自定义用户"
                v-model="forms.userName"
                allowClear
              ></a-input>
            </div>
          </a-form-model-item>

          <a-form-model-item label="封面">
            <a-upload
              :key="imgKey"
              :action="ip + '/file/upload'"
              list-type="picture-card"
              :file-list="fileList"
              @preview="handlePreview"
              @change="handleChange"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
          </a-form-model-item>

          <a-form-model-item label="搜索关键词" prop="searchKeyword">
            <a-input
              placeholder="搜索关键词"
              v-model="forms.searchKeyword"
            ></a-input>
          </a-form-model-item>

          <a-form-model-item label="初始点击量" prop="startClickNum">
            <a-input placeholder="初始点击量" v-model="forms.startClickNum"></a-input>
          </a-form-model-item>
          <a-form-model-item label="转发量" prop="forwardNum">
            <a-input placeholder="转发量" v-model="forms.forwardNum"></a-input>
          </a-form-model-item>
          <a-form-model-item label="排序" prop="sort">
            <a-input
              placeholder="排序(小的在前)"
              v-model="forms.sort"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label='发布时间'>
            <a-date-picker size='small' :defaultValue="defaultValue"  format='YYYY-MM-DD HH:mm:ss' placeholder='发布时间'  @change="changeTime" />
          </a-form-model-item>
          <a-form-model-item label="允许评论" prop="sort">
            <a-radio-group
              name="radioGroup"
              v-model="forms.allowComment"
              :defaultValue="0"
            >
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item label="内容">
            <div v-if="showEditPriview == false">
              <!-- <a-input v-model='editProxyObj.title' placeholder='请输入文章标题'></a-input> -->
              <quillEditor
                class="ql-editor"
                :options="options"
                v-model="editProxyObj.content"
                style="min-height: 200px"
              ></quillEditor>
              <div v-for="(items, index) in editProxyObj.editList" :key="index">
                <div class="center-img">
                  <a-icon
                    @click="removeEdit(index)"
                    style="
                      position: absolute;
                      right: 10px;
                      top: 10px;
                      font-size: 20px;
                      cursor: pointer;
                    "
                    type="close-circle"
                  />
                  <img style="width: 100%" :src="items.url" alt="" />
                  <a-input
                    v-model="items.desc"
                    style="position: absolute; bottom: 0px"
                    placeholder="请输入图片描述"
                  ></a-input>
                </div>
                <quillEditor
                  class="ql-editor"
                  :options="options"
                  placeholder="1"
                  v-model="items.content"
                  style="min-height: 200px"
                ></quillEditor>
              </div>
            </div>

            <div class="ql-editor" v-else v-html="editHtml"></div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import quillConfig from "../../../../untils/qullsample.config";
import { mapState } from "vuex";
import moment from "moment";
import 'moment/locale/zh-cn';
export default {
  components: {
    quillEditor,
  },

  computed: {
    ...mapState(["ip"]),
  },

  data() {
    return {
      moment,
      defaultValue: moment(new Date(), 'YYYY-MM-DD'),
      // editorOption: {
      //   placeholder: "编辑文章内容",
      // },
      innerTitle: "123",
      visible: false,
      showEditPriview: false,
      list: [
        { title: "ID", dataIndex: "id", align: "center" },
        { title: "标题", align: "center", dataIndex: "title" },
        {
          title: "封面图",
          dataIndex: "coverPicture",
          align: "center",
          scopedSlots: { customRender: "showImage" },
        },
        { title: "真实点击数", align: "center", dataIndex: "clickNum" },
        { title: "分享数", align: "center", dataIndex: "forwardNum" },
        {
          title: "是否推荐",
          dataIndex: "recommendStatus",
          key: "recommendStatus",
          customRender: (text) => {
            const inner = text;
            if (inner == 1) {
              return <span style="color:green">是</span>;
            }
            if (inner == 0) {
              return <span style="color:red">否</span>;
            }
          },
        },
        {
          title: "发布状态",
          dataIndex: "status",
          key: "status",
          customRender: (text) => {
            const inner = text;
            if (inner == 1) {
              return <span style="color:green">已发布</span>;
            }
            if (inner == 0) {
              return <span style="color:red">草稿</span>;
            }
          },
        },
        {
          title: "允许评论",
          dataIndex: "allowComment",
          key: "allowComment",
          customRender: (text) => {
            const inner = text;
            if (inner == 1) {
              return <span style="color:green">是</span>;
            }
            if (inner == 0) {
              return <span style="color:red">否</span>;
            }
          },
        },
        { title: "排序", align: "center", dataIndex: "sort" },
        { title: "发布时间", align: "center", dataIndex: "publishTime" },
        { title: "作者", align: "center", dataIndex: "userName" },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      params: { pageNum: 1, pageSize: 10, title: "", categoryId: "" },
      forms: {
        // showImageUrl:'',
        // revealImageUrl:'',
      },
      rules: {
        title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
        coverPicture: [{ required: true, message: "封面图", trigger: "blur" }],
      },
      layout: {
        labelCol: { span: 3 }, //标签占2份  共24份
        wrapperCol: { span: 21 },
      },
      searchInpt: "",
      category_list: [], //类别列表
      users: [],
      options: quillConfig,
      fileList: [],
      imgKey: "",

      edit_fileList: [],
      editProxyObj: {
        title: "",
        content: "",
        editList: [],
      },
      editHtml: "",
      currency_big_list: [],
    };
  },
  watch: {
    visible() {
      if (this.visible == true) {
      } else {
        this.fileList = [];
        this.showEditPriview = false;
        this.editHtml = "";
        this.editProxyObj = {
          // title:'',
          content: "",
          editList: [],
        };
        this.imgKey = this.visible ? "" : Math.random();
      }
    },
  },
  methods: {
    changeTime(data,date){
      this.forms.publishTime = date
    },
    async searchUser(str) {
      const res = await this.axios('/dq_admin/user/list',{params:{userName:str,limit:20}})
      if(res.status == 200){
          const {data} = res
          this.users = data
      }
    },
    getList(params) {
      this.axios("/dq_admin/bbsArticle/list", {
        params: params,
      }).then((res) => {
        let list = res.data.records;
        this.data = list;
        this.pagination.total = res.data.total;
      });
    },

    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList(this.params);
    },

    changeSelect(e) {
      this.params.categoryId = e;
    },

    previewImg(imageUrl) {
      if (imageUrl && imageUrl.length > 0) {
        this.$previewImg({
          list: [{ img_url: imageUrl }],
          baseImgField: "img_url",
          showMute: false,
        });
      }
    },

    cancel() {
      this.visible = false;
      this.forms = {};
      this.$refs.ruleForm.resetFields();
    },

    checkForms() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    async submit(type = 0) {
      const params = JSON.parse(JSON.stringify(this.forms));
      params.publishTime = moment(this.defaultValue).format('YYYY-MM-DD HH:mm:ss')
      if (type == 0) {
        this.setEditHtml();
        params.content = this.editHtml;
        params.content = params.content;
        params.sourceContent = JSON.stringify(this.editProxyObj);
      }
      try {
        params.coverPicture = this.fileList[0].url;
      } catch (err) {
        this.$message.warning("您未选择封面图");
      }
      if (params.userName) {
        params.userId = null
      }
      const res = await this.axios.post(
        "/dq_admin/bbsArticle/addOrEdit",
        params
      );
      if (res.status == 200) {
        const { message, data } = res;
        this.$message.success(message);
        if (type == 0) {
          this.getList(this.params);
          this.visible = false;
          this.cancel();
        } else {
          const { id } = data;
          this.forms.id = id;
        }
      }
    },

    getCategoryList() {
      this.axios("/dq_admin/bbsArticleCategory/list", {
        params: { pageNum: 1, pageSize: 100 },
      }).then((res) => {
        let list = res.data.records;
        this.category_list = list;
      });
    },

    async getUsers() {
      const res = await this.axios('/dq_admin/user/list',{params:{userName:"",limit:20}})
      if(res.status == 200){
          const {data} = res
          this.users = data
      }
    },

    parsetCallback(type, result) {
      if (type == 1) {
        this.forms.showImageUrl = result;
      }

      // this.$forceUpdate()
    },

    handleChange(res, forms, type) {
      const { response } = res.file;
      const { data } = response || { data: null };
      if (data) {
        if (type == 1) {
          this.$set(this.forms, "showImageUrl");
          this.forms.showImageUrl = data;
        }
      }
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    handleChange({ fileList }) {
      if (this.fileList.length > 1) {
        return this.$message.warning("只能选一张");
      }
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.fileList = fileList;
    },

    search(e) {
      this.params.page = 1;
      this.getList(this.params);
      this.pagination.current = 1;
    },

    valueNull() {
      // if (this.searchInpt == "") {

      // }
      this.getList(this.params);
      this.pagination.current = 1;
    },
    async openModal(row) {
      this.$set(this.forms, "publishTime", new Date())
      this.defaultValue = moment(new Date(), 'YYYY-MM-DD')
      if (row) {
        this.visible = true;
        this.forms = JSON.parse(JSON.stringify(row));
        if (!this.forms.publishTime) {
          this.defaultValue = ""
        } else {
          this.$set(this.forms, "publishTime", new Date(this.forms.publishTime))
          this.defaultValue = moment(this.forms.publishTime, 'YYYY-MM-DD')
        }
       
        if (this.forms.userId) this.forms.userName = ""
        const { sourceContent, coverPicture, coinKindName } = this.forms;
        this.searchData(coinKindName || "");
        this.fileList[0] = {
          uid: 0,
          name: "image",
          status: "done",
          url: coverPicture,
        };
        if (sourceContent != null && sourceContent != "") {
          this.editProxyObj = JSON.parse(sourceContent);
        }
      } else {
        this.openType = 2;
        this.visible = true;
        this.forms.allowComment = 0;
        this.innerTitle = "新增问题";
      }
    },

    remove(id) {
      this.axios("/dq_admin/bbsArticle/delById", {
        params: { id: id },
      }).then((res) => {
        this.getList(this.params);
        this.$message.success("删除成功");
      });
    },

    resetForms() {
      // this.coin_feature_list = [];
      // this.featureData = [{sort:""}];
    },
    addFeature() {
      this.featureData.push({});
    },
    removeare(index) {
      this.featureData.splice(index, 1);
    },
    onChangeChecked(event, index) {
      this.featureData[index].ids = event;
    },
    handleChangeEdit(row) {
      const { data } = row.file.response || { data: null };
      if (data != null) {
        this.editProxyObj.editList.push({
          url: data,
          desc: "",
          content: "",
        });
      }
    },
    removeEdit(n) {
      this.editProxyObj.editList.splice(n, 1);
    },
    setPreview() {
      this.showEditPriview = !this.showEditPriview;
      if (this.showEditPriview == true) {
        this.setEditHtml();
      } else {
        this.editHtml = "";
      }
    },
    setEditHtml() {
      this.editHtml = "<p>" + this.editProxyObj.content + "</p>";
      this.editProxyObj.editList.map((row) => {
        this.editHtml +=
          "<pre style='text-align:center;'><img style='width:100%;' src='" +
          row.url +
          "'></pre><div style='text-align:center;color:#8B8B8B;font-size:12px;'>" +
          row.desc +
          "</div>" +
          row.content;
      });
      this.editHtml =
        "<div style='white-space:pre-wrap;'>" + this.editHtml + "</div>";
      this.editHtml = this.editHtml.replaceAll(
        "\t",
        "&nbsp;&nbsp;&nbsp;&nbsp;"
      );
    },
    editSave() {
      this.setEditHtml();
      this.forms.content = this.editHtml;
      this.forms.sourceContent = JSON.stringify(this.editProxyObj);
      this.submit(1);
    },
    changeTag(s) {
      this.forms.categoryIds = s;
    },
    async setState(type, row) {
      const { id, recommendStatus } = row;
      let params, url;
      if (type == 1) {
        params = { id: id };
        url = "/dq_admin/bbsArticle/distribute";
      } else {
        params = { id: id, recommendStatus: recommendStatus === 0 ? 1 : 0 };
        url = "/dq_admin/bbsArticle/recommend";
      }
      const res = await this.axios(url, { params: params });
      if (res.status == 200) {
        this.$message.success(res.message || "操作成功");
        this.getList();
      }
    },
    searchData(inner) {
      this.axios("/dq_admin/kind/list", {
        params: { coinName: inner },
      }).then((res) => {
        if (res.status == "200") {
          const { records } = res.data;
          this.currency_big_list = records;
        }
      });
    },
  },
  mounted() {
    addEventListener("paste", (e) => {
      if (this.visible) {
        this.untils.pasteImg(e, 1, (a, b, s) => {
          this.editProxyObj.editList.push({
            url: b,
            desc: "",
            content: "",
          });
        });
      }
    });
    this.getList(this.params);
    this.getCategoryList();
    this.getUsers();
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    width: 65px;
    height: 28px;
    border: none;
    background: #1890ff;
    color: #ffffff;
    border-radius: 5px;
    margin-right: 25px;
    outline-style: none;
    cursor: pointer;
  }
}
.modal {
  input {
    margin-bottom: 5px;
  }
}
.center-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  background: rgba(241, 241, 241, 0.5);
  margin-top: 20px;
}
</style>
